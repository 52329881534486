<template>
  <div class="my-2">
    <h5 class="azul text-xl font-semibold">{{ office }}</h5>
    <div class="flex contenedor-el items-center">
      <img
        src="@/assets/img/contacts/icono2.png"
        alt="icono telefono"
        class="h-8  w-8 object-contain"
      />
      <a :href="`tel:+593${tele}`" target="_blank">
        <span class="azul text-sm">{{ phone }}</span>
      </a>
    </div>
    <div class="flex contenedor-el items-center">
      <img
        src="@/assets/img/contacts/icono4.png"
        alt="icono mapa"
        class="h-8 w-8 object-contain"
      />
      <span class="azul text-sm">{{ address }}</span>
    </div>
    <div class="flex contenedor-el items-center">
      <img
        src="@/assets/img/contacts/icono3.png"
        alt="icono telefono"
        class="h-8  w-8 object-contain"
      />
      <a :href="map" target="_blank">
        <span class="azul text-sm">¿Cómo llegar?</span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "office-c",
  props: ["office", "address", "phone", "tele", "map"]
};
</script>
