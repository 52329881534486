import axios from "axios";
import url from "./url";
import https from "https";

const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});

// SAVE PRODUCT
function saveProduct(
  name,
  description,
  simulator,
  characteristics,
  requirements,
  banner,
  icon,
  imgbeneficio,
  imgrequisitos,
  clientesSatisfechos,
  aniosExperiencia,
  creditosAprovados
) {
  return instance
    .post(`${url}/product/new-product`, {
      name,
      description,
      simulator,
      characteristics,
      requirements,
      banner,
      icon,
      imgbeneficio,
      imgrequisitos,
      clientesSatisfechos,
      aniosExperiencia,
      creditosAprovados
    })
    .then(resp => resp.data);
}

// UPDATE PRODUCT
function updateProduct(
  id,
  name,
  description,
  simulator,
  characteristics,
  requirements,
  banner,
  icon,
  imgbeneficio,
  imgrequisitos,
  clientesSatisfechos,
  aniosExperiencia,
  creditosAprovados
) {
  return instance
    .post(`${url}/product/save-edit-product`, {
      id,
      name,
      description,
      simulator,
      characteristics,
      requirements,
      banner,
      icon,
      imgbeneficio,
      imgrequisitos,
      clientesSatisfechos,
      aniosExperiencia,
      creditosAprovados
    })
    .then(resp => resp.data);
}

// UPLOAD IMAGE
function uploadImage(data) {
  let config = {
    header: {
      "Content-Type": "image/png"
    }
  };
  return instance.post(`${url}/product/save-image`, data, config).then(resp => {
    if (resp.data.status == "ok") {
      return resp.data.image;
    }
  });
}

// LIST PRODUCTS
function listProducts() {
  return instance.post(`${url}/product/list-product-all`).then(resp => {
    // console.log("lista", resp.data);
    if (resp.data.status == "ok") {
      return resp.data.product;
    }
  });
}

// GET PRODUCT
function getProduct(slug) {
  return instance
    .post(`${url}/product/list-product-slug`, { slug })
    .then(resp => {
      // console.log("get Product resp: ", resp.data);
      if (resp.data.status == "ok") {
        return resp.data.product;
      }
    });
}
// DELETE PRODUCT
function deleteProduct(id) {
  return instance.post(`${url}/product/delete-product`, { id }).then(resp => {
    // console.log("Product delete resp: ", resp.data);
    if (resp.data.status == "ok") {
      return resp.data.product;
    }
  });
}

export default {
  saveProduct,
  uploadImage,
  listProducts,
  getProduct,
  updateProduct,
  deleteProduct
};
