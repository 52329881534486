var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{style:({
    'background-image':
      'url(' + require('@/assets/img/formularios/fondoFORMULARIO.jpg') + ')'
  }),attrs:{"id":"bkg"}},[_c('section',{staticClass:"pb-10"},[_c('div',{staticClass:"container mx-auto w-full md:w-1/2",attrs:{"data-aos":"fade-up","data-aos-duration":"1000"}},[_c('form',{staticClass:"p-4 md:p-6"},[_c('div',{staticClass:"grid grid-cols-3 mb-3"},[_c('div',{staticClass:"col-span-4 md:col-span-1"},[_vm._m(0),_c('br'),_c('div',{staticClass:"centrar"},[_c('router-link',{staticClass:"text-center azul font-semibold -mt-2 self-center bg-white hover:bg-blue-300 text-blue-800 py-1 px-2 rounded w-3/5 mx-2 items-center",attrs:{"to":"/formulario-ahorro"}},[_vm._v("Solicitar")])],1),_c('br')]),_c('div',{staticClass:"col-span-4 md:col-span-1 centrar"},[_vm._m(1),_c('br'),_c('div',{staticClass:"centrar"},[_c('router-link',{staticClass:"text-center azul font-semibold -mt-2 self-center bg-white hover:bg-blue-300 text-blue-800 py-1 px-2 rounded w-3/5 mx-2 items-center",attrs:{"to":"/formulario-credito"}},[_vm._v("Solicitar")])],1),_c('br')]),_c('div',{staticClass:"col-span-4 md:col-span-1"},[_vm._m(2),_c('br'),_c('div',{staticClass:"centrar"},[_c('router-link',{staticClass:"text-center azul font-semibold -mt-2 self-center bg-white hover:bg-blue-300 text-blue-800 py-1 px-2 rounded w-3/5 mx-2 items-center",attrs:{"to":"/formulario-inversiones"}},[_vm._v("Solicitar")])],1),_c('br')])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenedor mx-auto icono centrariconos"},[_c('img',{staticClass:"mx-auto h-24",attrs:{"src":require("@/assets/img/home/icono1.png"),"alt":"icono credil"}}),_c('h5',{staticClass:"text-center azul font-semibold -mt-2"},[_vm._v("Ahorros")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenedor mx-auto icono centrar centrariconos"},[_c('img',{staticClass:"mx-auto h-24",attrs:{"src":require("@/assets/img/home/icono2.png"),"alt":"icono credil"}}),_c('h5',{staticClass:"text-center azul font-semibold -mt-2"},[_vm._v("Créditos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenedor mx-auto icono centrariconos"},[_c('img',{staticClass:"mx-auto h-24",attrs:{"src":require("@/assets/img/home/icono3.png"),"alt":"icono credil"}}),_c('h5',{staticClass:"text-center azul font-semibold -mt-2"},[_vm._v(" Inversiones ")])])
}]

export { render, staticRenderFns }