<template>
  <main id="main" :style="{
    'background-image': 'url(' + require('@/assets/img/home/fondo.jpg') + ')'
  }">
    <!-- BANNER -->
    <div id="banner" :style="{
      'background-image':
        'url(' + require('@/assets/img/transparency/banner.jpg') + ')'
    }">
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Transparencia</h2>
        <p class="text-gray-100">Home / Transparencia</p>
      </div>
    </div>

    <!-- CONTENIDO SUPERIOR -->
    <div class="mx-auto" data-aos="fade-up" data-aos-duration="1000">
      <h4 class="text-3xl azul my-2 font-bold text-center">
        Documentos
      </h4>

      <p class="azul text-center text-sm w-full md:w-2/4 mx-auto mb-10">
        La transparencia es uno de nuestros ejes de trabajo, descubre porque
        nuestros Socios confían en nosotros.
      </p>
    </div>

    <!-- ANIO -->
    <div class="container ">
      <span class="px-4 py-1 text-white rounded text-xl font-semibold" style="background-color:#0a5593;">Año 2024</span>
    </div>

    <!-- SECTION ICONOS -->
    <div class="flex flex-col md:flex-row justify-between p-10 mx-4 md:mx-32">
      <!-- Icono 1 -->

      <div class="contenedor mx-auto icono">
        <a href="/pdf/BalanceGeneral.pdf" target="_blank">
          <img src="@/assets/img/transparency/icono1.png" alt="icono credil" class="mx-auto h-24" />
          <h5 class="text-center azul font-semibold -mt-2">Balance General</h5>
        </a>
      </div>

      <div class="contenedor mx-auto icono">
        <a href="/pdf/BalanceSocial2022.pdf" target="_blank">
          <img src="@/assets/img/transparency/icono1.png" alt="icono credil" class="mx-auto h-24" />
          <h5 class="text-center azul font-semibold -mt-2">
            Balance Social 2022
          </h5>
        </a>
      </div>

      <!-- Icono 2 -->
      <div class="contenedor mx-auto icono">
        <a href="/pdf/EstadoDeResultados.pdf" target="_blank">
          <img src="@/assets/img/transparency/icono2.png" alt="icono credil" class="mx-auto h-24" />
          <h5 class="text-center azul font-semibold -mt-2">
            Estado de Resultados
          </h5>
        </a>
      </div>

      <!-- Icono 3 -->
      <div class="contenedor mx-auto icono">
        <a href="/pdf/IndicadoresFinancieros.pdf" target="_blank">
          <img src="@/assets/img/transparency/icono3.png" alt="icono credil" class="mx-auto h-24" />
          <h5 class="text-center azul font-semibold -mt-2">
            Indicadores Financieros
          </h5>
        </a>
      </div>

      <!-- Icono 4 -->
      <div class="contenedor mx-auto icono">
        <a href="/pdf/CostosFinancieros.pdf" target="_blank">
          <img src="@/assets/img/transparency/icono4.png" alt="icono credil" class="mx-auto h-24" />
          <h5 class="text-center azul font-semibold -mt-2">
            Costos Financieros
          </h5>
        </a>
      </div>
    </div>
    <!-- SECTION ICONOS -->
    <div class="flex flex-col md:flex-row justify-between p-10 mx-4 md:mx-32">
      <!-- Icono 1 -->
      <div class="contenedor mx-auto icono">
        <a href="/pdf/IndiceGenero.pdf" target="_blank">
          <img src="@/assets/img/transparency/icono5.png" alt="icono credil" class="mx-auto h-24" />
          <h5 class="text-center azul font-semibold -mt-2">
            Inclusión Financiera por Genero
          </h5>
        </a>
      </div>
      <div class="contenedor mx-auto icono">
        <a href="/pdf/ManualAdministracionRiesgoAmbientalSocialAPROB_SEP23.pdf" target="_blank">
          <img src="@/assets/img/transparency/icono4.png" alt="icono credil" class="mx-auto h-24" />
          <h5 class="text-center azul font-semibold -mt-2">
            Manual de Riesgo Ambiental y Social SARAS
          </h5>
        </a>
      </div>
      <div class="contenedor mx-auto icono">
        <a href="/pdf/ManualMetodologiasProcedimientosAdmRiesgoAmbSocSARAS.pdf" target="_blank">
          <img src="@/assets/img/transparency/icono4.png" alt="icono credil" class="mx-auto h-24" />
          <h5 class="text-center azul font-semibold -mt-2">
            Manual de Metodologías y Procedimientos Adm. Riesgo Ambiental y Social SARAS
          </h5>
        </a>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "Transparency"
};
</script>

<style scoped>
/* main */
#main {
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100vw;
  z-index: 1;
}

#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}

.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}

.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}

/* ICONS */
.icono {
  width: 180px;
  height: 180px;
  padding: 10px;
  box-shadow: none;
  transition: all 0.1s ease;
  border-radius: 50%;
  background-color: white;
}

.icono:hover {
  position: relative;
  top: 0px;
  box-shadow: 0 0 7px #999;
  border-radius: 50%;
  transform: translateY(-0.4px);
}
</style>
