<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/contacts/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Contactos</h2>
        <p class="text-gray-100">Home / Contactos</p>
      </div>
    </div>

    <!-- CONTENT -->
    <section>
      <div class="container mt-12  px-4 md:px-20">
        <!-- DIRECTIONS -->
        <div class="grid grid-cols-2 pb-12">
          <div class="col-span-2 md:col-span-1 px-4 ">
            <office-c
              office="Matriz Salcedo"
              phone="(03)2 730307"
              tele="032730307"
              address="Av. 24 de Mayo y Ana Paredes"
              map="https://www.google.com.ec/maps/place/1%C2%B002'37.3%22S+78%C2%B035'15.3%22W/@-1.043639,-78.5887394,18.2z/data=!4m5!3m4!1s0x0:0x0!8m2!3d-1.0436874!4d-78.5875802?hl=es"
            />
            <office-c
              office="Sucursal Izamba"
              phone="(03)2 451403"
              tele="032451403"
              address="Av. Pedro Vasconez, entre el ex Redondel de Izamba y el Colegio Tirso de Molina"
              map="https://www.google.com.ec/maps/place/1%C2%B013'35.5%22S+78%C2%B035'44.3%22W/@-1.2265179,-78.5978353,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d-1.2265179!4d-78.5956466?hl=es"
            />
            <office-c
              office="Sucursal  Ambato Centro"
              phone="(03)2 829542"
              tele="032829542"
              address="Av. 12 de Noviembre y Maldonado"
              map="https://www.google.com.ec/maps/place/1%C2%B014'22.3%22S+78%C2%B037'25.9%22W/@-1.2395318,-78.6260378,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d-1.2395318!4d-78.6238491?hl=es"
            />
            <office-c
              office="Sucursal Mercado Mayorista"
              phone="(03)2 406454"
              tele="032406454"
              address="Av. Bolivariana Interior del Mercado Mayorista"
              map="https://www.google.com.ec/maps/place/1%C2%B016'14.9%22S+78%C2%B036'51.9%22W/@-1.2707957,-78.6166168,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d-1.2707957!4d-78.6144281?hl=es"
            />
            <office-c
              office="Sucursal Riobamba"
              phone="(03)2 960535"
              tele="032960535"
              address="Chile y Juan Montalvo"
              map="https://www.google.com/maps?q=-1.6716872453689575,-78.65584564208984&z=17&hl=es"
            />
            <office-c
              office="Sucursal Machala"
              phone="  "
              tele="0993504954"
              address="Av. 25 de Junio entre Buenavista y Colón "
              map="https://www.google.com/maps?q=-3.2620435,-79.9555515&z=17&hl=es"
            />
            <office-c
              office="Sucursal Guayaquil"
              phone=" 042310860 "
              tele="0993504954"
              address="Av. 9 de Octubre y Lorenzo de Garaycoa "
              map="https://www.google.com/maps?q=-2.1896049,-79.8869335&z=17&hl=es"
            />
            <office-c
              office="Sucursal Quito - Cotocollao"
              phone="0986022199"
              tele="0986022199"
              address="Av. Diego de Vázquez y Nazacota Puento (esq.)"
              map="https://www.google.com/maps/place/Avenida+Diego+V%C3%A1squez+de+Cepeda+%26+Nazacota+Puento,+Quito+170144/data=!4m2!3m1!1s0x91d5857197a10279:0xfaa19e10cd6ca828?utm_source=mstt_1&entry=gps&lucs=karto&g_ep=CAESCTExLjYwLjcwMxgAIIgnKgVrYXJ0b0ICRUM%3D"
            />
            <office-c
              office="Sucursal Pasaje "
              phone="0981479207"
              tele="0981479207"
              address="Av. Rocafuerte y 9 de Octubre"
              map="https://www.google.com/maps?q=-3.325507164001465,-79.80706024169922&z=17&hl=esñañ"
            />
            <office-c
              office="Sucursal Piñas"
              phone="0993426725"
              tele="0993426725"
              address="Av. La independencia y Juan Montalvo"
              map="https://www.google.com/maps?q=-3.680195,-79.6838044&z=17&hl=es"
            />
          </div>
          <div class="col-span-2 md:col-span-1" id="slider-office">
            <slider-offices />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import OfficeC from "../components/contacts/OfficeC.vue";
import SliderOffices from "../components/contacts/SliderOffices";
export default {
  components: { OfficeC, SliderOffices },
  name: "Contacts"
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
</style>
