var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{style:({
  'background-image': 'url(' + require('@/assets/img/home/fondo.jpg') + ')'
}),attrs:{"id":"main"}},[_c('div',{style:({
    'background-image':
      'url(' + require('@/assets/img/transparency/banner.jpg') + ')'
  }),attrs:{"id":"banner"}},[_vm._m(0)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"texto-banner"},[_c('h2',{staticClass:"text-white text-3xl font-bold"},[_vm._v("Transparencia")]),_c('p',{staticClass:"text-gray-100"},[_vm._v("Home / Transparencia")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto",attrs:{"data-aos":"fade-up","data-aos-duration":"1000"}},[_c('h4',{staticClass:"text-3xl azul my-2 font-bold text-center"},[_vm._v(" Documentos ")]),_c('p',{staticClass:"azul text-center text-sm w-full md:w-2/4 mx-auto mb-10"},[_vm._v(" La transparencia es uno de nuestros ejes de trabajo, descubre porque nuestros Socios confían en nosotros. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('span',{staticClass:"px-4 py-1 text-white rounded text-xl font-semibold",staticStyle:{"background-color":"#0a5593"}},[_vm._v("Año 2024")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col md:flex-row justify-between p-10 mx-4 md:mx-32"},[_c('div',{staticClass:"contenedor mx-auto icono"},[_c('a',{attrs:{"href":"/pdf/BalanceGeneral.pdf","target":"_blank"}},[_c('img',{staticClass:"mx-auto h-24",attrs:{"src":require("@/assets/img/transparency/icono1.png"),"alt":"icono credil"}}),_c('h5',{staticClass:"text-center azul font-semibold -mt-2"},[_vm._v("Balance General")])])]),_c('div',{staticClass:"contenedor mx-auto icono"},[_c('a',{attrs:{"href":"/pdf/BalanceSocial2022.pdf","target":"_blank"}},[_c('img',{staticClass:"mx-auto h-24",attrs:{"src":require("@/assets/img/transparency/icono1.png"),"alt":"icono credil"}}),_c('h5',{staticClass:"text-center azul font-semibold -mt-2"},[_vm._v(" Balance Social 2022 ")])])]),_c('div',{staticClass:"contenedor mx-auto icono"},[_c('a',{attrs:{"href":"/pdf/EstadoDeResultados.pdf","target":"_blank"}},[_c('img',{staticClass:"mx-auto h-24",attrs:{"src":require("@/assets/img/transparency/icono2.png"),"alt":"icono credil"}}),_c('h5',{staticClass:"text-center azul font-semibold -mt-2"},[_vm._v(" Estado de Resultados ")])])]),_c('div',{staticClass:"contenedor mx-auto icono"},[_c('a',{attrs:{"href":"/pdf/IndicadoresFinancieros.pdf","target":"_blank"}},[_c('img',{staticClass:"mx-auto h-24",attrs:{"src":require("@/assets/img/transparency/icono3.png"),"alt":"icono credil"}}),_c('h5',{staticClass:"text-center azul font-semibold -mt-2"},[_vm._v(" Indicadores Financieros ")])])]),_c('div',{staticClass:"contenedor mx-auto icono"},[_c('a',{attrs:{"href":"/pdf/CostosFinancieros.pdf","target":"_blank"}},[_c('img',{staticClass:"mx-auto h-24",attrs:{"src":require("@/assets/img/transparency/icono4.png"),"alt":"icono credil"}}),_c('h5',{staticClass:"text-center azul font-semibold -mt-2"},[_vm._v(" Costos Financieros ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col md:flex-row justify-between p-10 mx-4 md:mx-32"},[_c('div',{staticClass:"contenedor mx-auto icono"},[_c('a',{attrs:{"href":"/pdf/IndiceGenero.pdf","target":"_blank"}},[_c('img',{staticClass:"mx-auto h-24",attrs:{"src":require("@/assets/img/transparency/icono5.png"),"alt":"icono credil"}}),_c('h5',{staticClass:"text-center azul font-semibold -mt-2"},[_vm._v(" Inclusión Financiera por Genero ")])])]),_c('div',{staticClass:"contenedor mx-auto icono"},[_c('a',{attrs:{"href":"/pdf/ManualAdministracionRiesgoAmbientalSocialAPROB_SEP23.pdf","target":"_blank"}},[_c('img',{staticClass:"mx-auto h-24",attrs:{"src":require("@/assets/img/transparency/icono4.png"),"alt":"icono credil"}}),_c('h5',{staticClass:"text-center azul font-semibold -mt-2"},[_vm._v(" Manual de Riesgo Ambiental y Social SARAS ")])])]),_c('div',{staticClass:"contenedor mx-auto icono"},[_c('a',{attrs:{"href":"/pdf/ManualMetodologiasProcedimientosAdmRiesgoAmbSocSARAS.pdf","target":"_blank"}},[_c('img',{staticClass:"mx-auto h-24",attrs:{"src":require("@/assets/img/transparency/icono4.png"),"alt":"icono credil"}}),_c('h5',{staticClass:"text-center azul font-semibold -mt-2"},[_vm._v(" Manual de Metodologías y Procedimientos Adm. Riesgo Ambiental y Social SARAS ")])])])])
}]

export { render, staticRenderFns }