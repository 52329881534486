import { render, staticRenderFns } from "./admincreditos.vue?vue&type=template&id=eac8a702&scoped=true"
import script from "./admincreditos.vue?vue&type=script&lang=js"
export * from "./admincreditos.vue?vue&type=script&lang=js"
import style0 from "./admincreditos.vue?vue&type=style&index=0&id=eac8a702&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eac8a702",
  null
  
)

export default component.exports