<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/simulator/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <div class="contenedor-imagen pt-10">
          <img
            src="@/assets/img/simulator/icono.png"
            alt="icono simulador"
            class="mx-auto"
            style="height: 200px; width:auto;"
          />
        </div>
        <h2 class="text-white text-3xl font-bold text-center">
          Simulador de Créditos
        </h2>
        <p class="text-gray-100 text-center">
          Grandes beneficios y la mejor tasa de interés
        </p>
      </div>
    </div>
    <section class="container p-4 md:px-32 mx-30 ">
      <form class="container mx-auto bg-azul-fuerte rounded-b-lg rounded-t-sm ">
        <!-- FILA 1 -->
        <h5 class="bg-white text-xl  text-blue-900 py-2 px-6 rounded-t-sm">
          Ingrese los datos
        </h5>
        <div class="grid grid-cols-2 p-2  pt-4">
          <!-- TIPO DE CREDITO -->
          <div class="col-span-2 md:col-span-1 mx-0 md:mx-10">
            <label for="tipoCredito" class="text-white block "
              >Tipo de Crédito</label
            >
            <select
              name="tipo"
              id="tipoCredito"
              v-model="tipoCredito"
              class="p-2 my-1 mr-1 rounded focus:outline-none w-full md:w-11/12"
            >
              <!-- <option value="crediLaboral">Credi Laboral</option>
              <option value="crediManager">Credi Manager</option> -->
              <option value="consumo" selected>Consumo</option>
              <option value="simple">Microcrédito</option>
            </select>
          </div>

          <!-- MONTO -->
          <div class="col-span-2 md:col-span-1 mx-0 md:mx-10">
            <label for="tipoCredito" class="text-white block">Monto</label>
            <input
              type="number"
              id="cantidad"
              v-model="cantidad"
              class="p-2 my-1 mr-1 rounded focus:outline-none w-full md:w-11/12"
              placeholder="Monto"
            />
          </div>
        </div>

        <!-- FILA 2 -->
        <div class="grid grid-cols-2 p-2 ">
          <!-- PLAZO -->
          <div class="col-span-2 md:col-span-1 mx-0 md:mx-10">
            <label for="tipoCredito" class="text-white block"
              >Plazo en Meses</label
            >
            <input
              type="number"
              id="plazo"
              v-model="plazo"
              class="p-2 my-1 mr-1 rounded focus:outline-none w-full md:w-11/12"
              min="6"
              max="80"
              maxlength="3"
              placeholder="Meses"
            />
          </div>
          <!-- AMORTIZACION -->
          <div class="col-span-2 md:col-span-1 mx-0 md:mx-10">
            <!-- <label for="amortizacion" class="text-white block"
              >Amortización</label
            >
            <select
              name="tipo"
              id="amortizacion"
              v-model="amortizacion"
              class="p-2 my-1 mr-1 rounded focus:outline-none w-full md:w-11/12"
            > -->
            <!-- <option value="cuotaFija" selected>Cuota Fija</option> -->
            <!-- <option value="cuotaVariable" selected>Cuota Variable</option>
            </select> -->
          </div>
        </div>

        <!-- ENVIAR -->
        <div class="flex justify-around py-8 w-full px:2 md:px-48">
          <button
            type="button"
            class="bg-gray-700 px-3 md:px-10 py-2 rounded text-white focus:outline-none"
            @click="simulation"
            @keydown.tab.exact.prevent=""
          >
            Simular
          </button>
          <button
            type="button"
            class="bg-gray-500 px-3 md:px-10 py-2 rounded text-white focus:outline-none"
            @click="limpiar"
            @keydown.tab.exact.prevent=""
          >
            Limpiar
          </button>
          <router-link
            to="/formulario-credito"
            class="bg-gray-700 px-3 md:px-10 py-2 rounded text-white focus:outline-none"
          >
            Solicitar
          </router-link>
        </div>
      </form>
    </section>

    <!-- RESULTS -->
    <section class="container w-full">
      <bounce-loader
        class="mx-auto my-10"
        :loading="isLoading"
        :color="'#063a79'"
        :size="100"
      />
    </section>

    <section
      class="container py-5 px-4 md:px-32 mx-auto bg-white w-full"
      v-if="showTable"
    >
      <h2 class="text-2xl px-4 text-white bg-azul-fuerte rounded py-1">
        Tabla de Amortización
      </h2>
      <div class="overflow-auto">
        <table class="w-full my-5 rounded shadow">
          <thead class="">
            <tr class="border border-gray-200 bg-white pt-5">
              <th class="uppercase text-blue-800 text-sm py-2">Nº Cuota</th>
              <!-- <th class="uppercase text-blue-800 text-sm">Fecha</th>
              <th class="uppercase text-blue-800 text-sm">Saldo</th> -->
              <th class="uppercase text-blue-800 text-sm">Capital</th>
              <th class="uppercase text-blue-800 text-sm">Interés</th>
              <th class="uppercase text-blue-800 text-sm">Seguro</th>
              <th class="uppercase text-blue-800 text-sm">Cuota</th>
            </tr>
          </thead>
          <tbody class="text-center bg-gray-100">
            <tr
              v-for="(a, i) in list"
              :key="a._id"
              class="border-b  border-gray-200 hover:bg-blue-100"
            >
              <td class="py-2 mb-1">{{ i + 1 }}</td>
              <!-- <td class="mb-2">
                {{ fecha.getFullYear() }}-{{ fecha.getMonth() + i }}-{{
                  fecha.getDate()
                }}
              </td>
              <td>
                {{ a.cantidad }}
              </td> -->
              <td>
                {{ a.capital }}
              </td>
              <td>
                {{ a.intereses }}
              </td>
              <td>
                {{ a.segurodegravamen }}
              </td>
              <td>
                {{ a.cuota }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="container text-center px-2 md:px-20">
          <p class="text-sm">
            <strong>Nota:</strong> La Información presentada está sujeta a
            variaciones y verificación por parte de la Cooperativa Credil. Los
            cálculos ofrecidos tienen carácter orientativo, estando sujetas a
            variaciones y aprobación posterior.
          </p>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import api from "@/api/simulator";
export default {
  name: "SimulatorCredit",
  data() {
    return {
      list: [],
      tipoCredito: "",
      cantidad: "",
      plazo: "",
      amortizacion: "cuotaVariable",
      fecha: new Date(),
      showTable: false,
      isLoading: false
    };
  },
  methods: {
    simulation() {
      if (
        this.tipoCredito == "" ||
        this.cantidad == "" ||
        this.plazo == "" ||
        this.amortizacion == ""
      ) {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        this.isLoading = true;
        this.showTable = false;
        api
          .consultSimulation(
            this.tipoCredito,
            this.cantidad,
            this.plazo,
            this.amortizacion
          )
          .then(resp => {
            // console.log(resp);
            if (resp.status == "ok") {
              this.isLoading = false;
              this.showTable = true;
              // this.$swal("Consultado", "", "success").then(() => {});
              this.list = resp.tablaSimulador;
            } else {
              this.$swal("Error", resp.message, "warning");
              this.isLoading = false;
            }
          });
      }
    },
    limpiar() {
      this.tipoCredito = "";
      this.cantidad = "";
      this.plazo = "";
      this.amortizacion = "";
      this.showTable = false;
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.bg-azul-fuerte {
  background-color: #063a79;
}
</style>
