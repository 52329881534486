import axios from "axios";
import url from "./url";
import https from "https";

const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
    requestCert: true
  })
});

// SAVE
function saveForm(body) {
  console.log("enviado body", body);
  return instance
    .post(`${url}/trabaja-con-nosotros/new-formulario`, {
      body
    })
    .then(resp => resp.data);
}

// LIST
function listForms(fechaInicio, fechaFin) {
  return instance
    .post(`${url}/trabaja-con-nosotros/list-formulario-all`, {
      fechaInicio,
      fechaFin
    })
    .then(resp => {
      if (resp.data.status == "ok") {
        return resp.data.formulario;
      }
    });
}

// Delete
function deleteForm(id) {
  return instance
    .post(`${url}/trabaja-con-nosotros/delete-formulario`, { id })
    .then(resp => {
      if (resp.data.status == "ok") {
        return resp.data;
      }
    });
}

// UPLOAD PDf
function uploadPdf(data) {
  let config = {
    header: {
      "Content-Type": "application/pdf"
    }
  };
  return instance
    .post(`${url}/trabaja-con-nosotros/save-pdf`, data, config)
    .then(resp => {
      if (resp.data.status == "ok") {
        return resp.data.image;
      }
    });
}

export default {
  saveForm,
  listForms,
  uploadPdf,
  deleteForm
};
