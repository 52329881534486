<template>
  <main
    id="bkg"
    :style="{
      'background-image':
        'url(' + require('@/assets/img/formularios/fondoFORMULARIO.jpg') + ')'
    }"
  >
    <!-- SECTION NOSOTROS -->
    <section class="pb-10">
      <!-- DESCRIPTION -->

      <!-- FORMULARIO -->
      <div
        class="container mx-auto w-full md:w-1/2   "
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <form class="p-4 md:p-6">
          <!-- col 1 -->
          <div class="grid grid-cols-3 mb-3">
            <div class="col-span-4 md:col-span-1">
              <div class="contenedor mx-auto icono centrariconos">
                <img
                  src="@/assets/img/home/icono1.png"
                  alt="icono credil"
                  class="mx-auto h-24"
                />
                <h5 class="text-center azul font-semibold -mt-2">Ahorros</h5>
              </div>
              <br />
              <div class="centrar">
                <router-link
                  to="/formulario-ahorro"
                  class="text-center azul font-semibold -mt-2 self-center bg-white hover:bg-blue-300 text-blue-800 py-1 px-2 rounded w-3/5 mx-2 items-center "
                  >Solicitar</router-link
                >
              </div>
              <br />
            </div>

            <div class="col-span-4 md:col-span-1 centrar">
              <!-- Icono 2 -->
              <div class="contenedor mx-auto icono centrar centrariconos">
                <img
                  src="@/assets/img/home/icono2.png"
                  alt="icono credil"
                  class="mx-auto h-24"
                />
                <h5 class="text-center azul font-semibold -mt-2">Créditos</h5>
              </div>
              <br />
              <div class="centrar">
                <router-link
                  to="/formulario-credito"
                  class=" text-center azul font-semibold -mt-2 self-center bg-white hover:bg-blue-300 text-blue-800 py-1 px-2 rounded w-3/5 mx-2 items-center "
                  >Solicitar</router-link
                >
              </div>
              <br />
            </div>
            <div class="col-span-4 md:col-span-1 ">
              <!-- Icono 3 -->
              <div class="contenedor mx-auto icono centrariconos ">
                <img
                  src="@/assets/img/home/icono3.png"
                  alt="icono credil"
                  class="mx-auto h-24"
                />
                <h5 class="text-center azul font-semibold -mt-2">
                  Inversiones
                </h5>
              </div>
              <br />
              <div class="centrar">
                <router-link
                  to="/formulario-inversiones"
                  class="text-center azul font-semibold -mt-2 self-center bg-white hover:bg-blue-300 text-blue-800 py-1 px-2 rounded w-3/5 mx-2 items-center "
                  >Solicitar</router-link
                >
              </div>
              <br />
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "inquiries",

  methods: {}
};
</script>

<style scoped>
.centrar {
  text-align: center;
}
.icono {
  width: 220px;
  height: 220px;
  padding: 10px;
  box-shadow: none;
  transition: all 0.1s ease;
  border-radius: 50%;
  background-color: white;
}
.icono:hover {
  position: relative;
  top: 0px;
  box-shadow: 0 0 7px rgba(192, 201, 233, 0.479);
  border-radius: 50%;
  transform: translateY(-0.4px);
}
.titulo {
  padding-top: 30px;
}
.tamtit {
  font-size: 50px;
}
.centrariconos {
  padding-top: 40px;
}
</style>
