<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/governance/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Gobernanza</h2>
        <p class="text-gray-100">Home / Gobernanza</p>
      </div>
    </div>

    <!-- CONTENT -->
    <section>
      <!-- CONTENT TEXT -->
      <div class="owners bg-white py-4">
        <div class=" mx-auto px-2 md:px-20">
          <span
            class="px-4 py-1 text-white rounded text-xl font-semibold"
            style="background-color:#0a5593;"
            >Consejo de Administración</span
          >
          <!-- <p class="azul text-justify w-full md:w-2/4 text-sm my-2">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi, sed!
            Eaque, amet quae? Quas deserunt hic, magnam iusto alias accusamus
            commodi.
          </p> -->
        </div>
        <div class="grid grid-cols-2 mx-auto px-2 md:px-20">
          <!-- COL 1 -->
          <div class="col-span-2 md:col-span-1">
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <img
                src="@/assets/img/governance/iconoh.png"
                alt="icono hombre"
                class="w-20 h-20"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase"
                  >NELSON XAVIER VALLEJO RUIZ</span
                >
                <span class="gris font-thin -mt-1 block">Presidente</span>
              </div>
            </div>
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <img
                src="@/assets/img/governance/iconoh.png"
                alt="icono hombre"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase"
                  >FREDY ELISEO PAUCAR YANCHAPANTA</span
                >
                <span class="gris font-thin -mt-1 block">Vicepresidente</span>
              </div>
            </div>
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase"
                  >ANA CRISTINA LAGUA GUAITA</span
                >
                <span class="gris font-thin -mt-1 block">Secretaria</span>
              </div>
            </div>
          </div>

          <!-- COL 2 -->
          <div class="col-span-2 md:col-span-1">
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase"
                  >GIOVANNA BETSABE LIZANO LOPEZ</span
                >
                <span class="gris font-thin -mt-1 block">Vocal principal</span>
              </div>
            </div>
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <img
                src="@/assets/img/governance/iconoh.png"
                alt="icono hombre"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase"
                  >EDGAR DARIO OLOVACHA CAHUANA</span
                >
                <span class="gris font-thin -mt-1 block">Vocal principal</span>
              </div>
            </div>

            <!-- REPRESNT -->
            <!---- <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <img
                src="@/assets/img/governance/iconoh.png"
                alt="icono hombre"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase"
                  >EDGAR DARIO OLOVACHA CAHUANA</span
                >
                <span class="gris font-thin -mt-1 block">Vocal principal</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- Consejo de Vigilancia BANNER-->
      <section>
        <div
          class="img-banner-bg  z-0"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/governance/banner2.jpg') + ')'
          }"
        >
          <div
            class="texto-principal-right"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3
              class="text-white text-5xl font-bold text-center mb-6 "
              style="line-height: 3rem"
            >
              Contamos con 13 años de <br />experiencia financiera
            </h3>
            <div class="flex justify-center">
              <p class="text-white text-center w-full md:w-1/2 text-sm">
                Nunca dudes que un pequeño grupo de personas comprometidas pueda
                cambiar el mundo. De hecho, es lo único que lo ha logrado.
              </p>
            </div>
          </div>
        </div>
      </section>

      <!-- CONSEJO DE VIGILANCIA-->
      <div class="owners bg-white py-4">
        <div class=" mx-auto px-2 md:px-20">
          <span
            class="px-4 py-1 text-white rounded text-xl font-semibold"
            style="background-color:#0a5593;"
            >Consejo de Vigilancia</span
          >
        </div>
        <div class="grid grid-cols-2 mx-auto px-2 md:px-20">
          <!-- COL 1 -->
          <div class="col-span-2 md:col-span-1">
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase"
                  >SILVANA MARIBEL MUQUINCHE PAREDES</span
                >
                <span class="gris font-thin -mt-1 block">Presidente</span>
              </div>
            </div>

            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase"
                  >ELIANA MARIBEL CRIOLLO PAUCAR</span
                >
                <span class="gris font-thin -mt-1 block">Secretaria</span>
              </div>
            </div>
          </div>

          <!-- COL 2 -->
          <div class="col-span-2 md:col-span-1">
            <!-- REPRESNT -->
            <div
              class="flex"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <img
                src="@/assets/img/governance/iconom.png"
                alt="icono mujer"
                class="w-20 h-20 inline-block"
              />
              <div class="textos my-3">
                <span class="block gris font-semibold uppercase"
                  >FREDDY PAUL CHILUIZA RUIZ</span
                >
                <span class="gris font-thin -mt-1 block">Vocal Principal</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CONSEJO DE VIGILANCIA-->
      <div
        class="owners bg-white py-4"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class=" mx-auto px-2 md:px-20">
          <span
            class="px-4 py-1 text-white rounded text-xl font-semibold mb-4"
            style="background-color:#0a5593;"
            >Organigrama</span
          >

          <!-- Light-box -->
          <light-box img1="/governance/organigrama.jpg" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import LightBox from "../../components/howWeAre/LightBox.vue";
export default {
  name: "Governance",
  components: {
    LightBox
  }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.img-banner-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal-right {
  position: relative;
  top: 20%;
}
</style>
