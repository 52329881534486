<template>
  <carousel
    :perPage="1"
    :autoplay="true"
    :autoplayTimeout="3000"
    :paginationEnabled="false"
    :paginationSize="0"
    :navigationEnabled="true"
    :loop="true"
    class="relative"
  >
    <slide>
      <h2 class="bg-azul text-white py-1 px-5">Agencia Matriz Salcedo</h2>
      <img
        src="@/assets/img/contacts/oficina-salcedo.jpg"
        alt="oficina credil"
      />
    </slide>
    <slide>
      <h2 class="bg-azul text-white py-1 px-5">Oficina Ambato - Izamba</h2>
      <img
        src="@/assets/img/contacts/oficina-izamba.jpg"
        alt="oficina credil"
      />
    </slide>
    <slide>
      <h2 class="bg-azul text-white py-1 px-5">Oficina Ambato - Centro</h2>
      <img
        src="@/assets/img/contacts/oficina-centro.jpg"
        alt="oficina credil"
      />
    </slide>
    <slide>
      <h2 class="bg-azul text-white py-1 px-5">Oficina Riobamba</h2>
      <img
        src="@/assets/img/contacts/oficina-riobamba.jpg"
        alt="oficina credil"
      />
    </slide>
    <slide>
      <h2 class="bg-azul text-white py-1 px-5">Oficina Machala</h2>
      <img
        src="@/assets/img/contacts/oficina-machala.jpg"
        alt="oficina credil"
      />
    </slide>
    <slide>
      <h2 class="bg-azul text-white py-1 px-5">Oficina Guayaquil</h2>
      <img
        src="@/assets/img/contacts/oficina-guayaquil.jpg"
        alt="oficina credil"
      />
    </slide>
    <slide>
      <h2 class="bg-azul text-white py-1 px-5">Oficina Pasaje</h2>
      <img
        src="@/assets/img/contacts/oficina-pasaje.jpg"
        alt="oficina credil"
      />
    </slide>
    <slide>
      <h2 class="bg-azul text-white py-1 px-5">Oficina Quito Cotocollao</h2>
      <img src="@/assets/img/contacts/oficina-quito.jpg" alt="oficina credil" />
    </slide>
    <slide>
      <h2 class="bg-azul text-white py-1 px-5">Oficina Piñas</h2>
      <img src="@/assets/img/contacts/oficina-pinas.jpg" alt="oficina credil" />
    </slide>
  </carousel>
</template>

<script>
export default {
  name: "SliderOffices"
};
</script>

<style>
#slider-office .VueCarousel-navigation-button {
  top: 0 !important;
}
#slider-office .VueCarousel-navigation-button.VueCarousel-navigation-prev {
  transform: translateY(530px) translateX(390px) !important;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px !important;
}
#slider-office .VueCarousel-navigation-button.VueCarousel-navigation-next {
  transform: translateY(530px) translateX(-50px) !important;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 7px !important;
}
@media screen and (max-width: 690px) {
  #slider-office .VueCarousel-navigation-button.VueCarousel-navigation-prev {
    transform: translateY(300%) translateX(0) !important;
  }
  #slider-office .VueCarousel-navigation-button.VueCarousel-navigation-next {
    transform: translateY(300%) translateX(0) !important;
  }
}
</style>
