var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","height":"500px"}},[_c('slider',{ref:"slider",attrs:{"options":_vm.options},on:{"slide":_vm.slide}},[_c('slideritem',{staticClass:"bg-blue-600 banner-slider",style:({
        'background-image':
          'url(' + require('@/assets/img/home/banner2.jpg') + ')'
      })},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.show)?_c('div',{staticClass:"animar container w-10/12 md:w-1/3 overflow-hidden p-3"},[_c('h2',{staticClass:"text-white text-left flex-wrap font-semibold leading-10 mb-5"},[_vm._v(" Seguridad y Confianza "),_c('br'),_vm._v(" con Nosotros ")]),_c('p',{staticClass:"text-white text-sm whitespace-normal text-justify"},[_vm._v(" Somos una entidad financiera con 13 años de experiencia en el Ecuador, contamos con el respaldo de nuestros Socios y somos regulados por todas las entidades reguladoras. ")])]):_vm._e()])],1),_c('slideritem',{staticClass:"bg-blue-600 banner-slider",style:({
        'background-image':
          'url(' + require('@/assets/img/home/banner1.jpg') + ')'
      })},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.show2)?_c('div',{staticClass:"animar container w-10/12 md:w-1/3 overflow-hidden p-3"},[_c('h2',{staticClass:"text-white text-left font-semibold leading-10 mb-5"},[_vm._v(" En DIOS "),_c('br'),_vm._v(" está nuestra Fortaleza ")]),_c('p',{staticClass:"text-white text-sm whitespace-normal text-justify"},[_vm._v(" Crecemos junto a nuestros Socios, haciendo sus sueños posibles. ")])]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }