<template>
  <div class="mb-4">
    <div class="container search">
      <div class="search m-4 container">
        <date-picker
          class="marginboton"
          v-model="date"
          type="date"
          format="YYYY-MM-DD"
          range
        ></date-picker>
        <button
          type="button"
          id="guardar"
          class=" bg-blue-500 hover:bg-blue-600 text-white  m-4 py-1 px-10 rounded shadow-md focus:outline-none"
          @click="consultar(date)"
          @keydown.tab.exact.prevent=""
        >
          Consultar
        </button>
      </div>
    </div>

    <div v-if="list.length > 0" class="overflow-x-auto">
      <table class=" w-full">
        <thead>
          <tr class="bg-gray-100 border-b-2  border-gray-400  ">
            <th>Nº</th>
            <!-- <th>Banner</th> -->
            <th>Nombre</th>
            <th>Fecha Nacimineto</th>
            <th>Correo</th>
            <th>Teléfono</th>
            <th>Tipo</th>
            <th>Cédula</th>
            <th>Nivel de Estudios</th>
            <th>Título Académico</th>
            <th>Estado Civil</th>
            <th>Género</th>
            <th>Fecha</th>
            <th>Provincia - Cantón - Parroquia</th>
            <th>Dirección</th>
            <th>Vehículo</th>
            <th>Situación Actual</th>
            <th>Salario Mínimo</th>
            <th>Provincia Trabajo</th>
            <th>Puesto de Trabajo</th>
            <th>Porque deseas formar parte</th>
            <th>CV PDF</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(a, i) in list"
            :key="a._id"
            class=" border-b-2 border-gray-200 hover:bg-orange-100"
          >
            <td>{{ i + 1 }}</td>
            <!-- <td><img :src="url + a.banner" :alt="a.name" class="h-10" /></td> -->
            <td>{{ a.names }}</td>
            <td class="text-justify">
              {{ a.born | moment("YYYY/MM/DD") }}
            </td>
            <td class="text-justify">
              {{ a.email }}
            </td>
            <td class="text-justify">
              {{ a.phone }}
            </td>
            <td class="text-justify">
              {{ a.typeIdentification }}
            </td>
            <td class="text-justify">
              {{ a.identification }}
            </td>
            <td class="text-justify">
              {{ a.studyLevel }}
            </td>
            <td class="text-justify">
              {{ a.title }}
            </td>
            <td class="text-justify">
              {{ a.civilState }}
            </td>
            <td class="text-justify">
              {{ a.gender }}
            </td>
            <td class="text-justify">
              {{ a.fecha_creacion | moment("YYYY/MM/DD") }}
            </td>
            <td class="text-justify">
              {{ a.province + " - " + a.parish + " - " + a.city }}
            </td>
            <td class="text-justify">
              {{ a.address }}
            </td>
            <td class="text-justify">
              {{ a.vehicle }}
            </td>
            <td class="text-justify">
              {{ a.actualSituation }}
            </td>
            <td class="text-justify">
              {{ a.salary }}
            </td>
            <td class="text-justify">
              {{ a.preferProvince }}
            </td>
            <td class="text-justify">
              {{ a.workstation }}
            </td>
            <td class="text-justify">
              {{ a.reason }}
            </td>

            <td class="text-justify">
              <a :href="url + a.cv" target="_blank">Pdf</a>
            </td>

            <td>
              <!-- delete product -->
              <button @click="deleteProd(a._id)">
                <span
                  class="bg-red-400 hover:bg-red-500 p-2 rounded inline-block m-1"
                >
                  <font-awesome-icon :icon="['fas', 'trash']" class="" />
                </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-center">
        <download-excel
          class="btn btn-default px-10 bg-blue-500 hover:bg-blue-700 mt-5 text-white w-2/12 mx-auto cursor-pointer rounded shadow"
          :data="json_data"
          type="xls"
          name="Trabaja-con-nosotros.xls"
        >
          Descargar a Excel
        </download-excel>
      </div>
    </div>
  </div>
</template>

<script>
import url from "@/api/url";
import api from "@/api/workWithUs";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "ListProducts",
  components: {
    DatePicker
  },
  data() {
    return {
      url,
      list: [],
      date: "",
      json_fields: {
        "Complete name": "name",
        City: "city",
        Telephone: "phone.mobile"
      },
      json_data: [
        {
          name: "Tony Peña",
          city: "New York",
          country: "United States",
          birthdate: "1978-03-15"
        }
      ],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  watch: {
    $route() {
      this.getProductList();
    }
  },
  created() {
    this.getProductList();
  },
  methods: {
    getProductList() {
      api.listForms().then(list => {
        console.log("lista de los productos", list);
        this.list = list;
        this.json_data = list;
      });
    },

    deleteProd(id) {
      console.log("borrando", id);
      this.$swal({
        title: "¿Estás seguro de borrar?",
        text: "Esto no se puede revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, bórralo!"
      }).then(result => {
        if (result.value) {
          api.deleteForm(id).then(() => {
            this.$swal(
              "Borrado!",
              "Su archivo ha sido borrado.",
              "success"
            ).then(() => {
              this.getProductList();
            });
          });
        }
      });
    },

    consultar(date) {
      if (date != "") {
        var fechaInicio = date[0];
        var fechaFin = date[1];

        // console.log(fechaInicio, fechaFin);
        api.listForms(fechaInicio, fechaFin).then(list => {
          console.log("lista: ", list);
          if (list.length > 0) {
            this.list = list;
          } else {
            this.$swal("No hay registros para esas fechas", "", "error");
            this.list = list;
          }
        });
      } else {
        this.$swal("Ingrese una fecha", "", "error");
      }
    }
  }
};
</script>

<style scoped>
td {
  padding: 20px 0px;
  font-size: 0.6rem;
  text-align: center;
}

th {
  padding: 5px;
  font-size: 0.6rem;
}

@media (min-width: 640px) {
  td,
  th {
    padding: 5px 10px;
    font-size: 1rem;
    border: 1px solid #eee;
  }

  th {
    padding: 10px;
  }
}
</style>
