<template>
  <main>
    <modal
      name="modal-new-user"
      :adaptive="true"
      transition="pop-out"
      height="auto"
      :width="450"
      :scrollable="true"
    >
      <img
        src="@/assets/img/home/banners/v2/bannersRedes.png"
        alt="popup credil"
      />
    </modal>
    <div class="fixed z-10 mt-16" style="right:0;">
      <div class="flex flex-col justify-center content-end ">
        <div class="cont-icon-fixed uno bg-azul mb-1 rounded-l-lg">
          <router-link to="/producto/depositos-a-plazo-fijo">
            <img
              src="@/assets/img/home/iconoBLANCO1.png"
              alt="icono"
              class="mx-auto h-12 md:h-16"
            />
            <span class="text-white text-sm text-center -mt-2 block px-2"
              >Inversiones</span
            >
          </router-link>
        </div>
        <div class="cont-icon-fixed dos bg-azul mb-1 rounded-l-lg">
          <router-link to="/simulador-inversiones">
            <img
              src="@/assets/img/home/iconoBLANCO2.png"
              alt="icono"
              class="mx-auto h-12 md:h-16"
            />
            <span class="text-white text-sm text-center -mt-2 block px-2"
              >Simuladores</span
            >
          </router-link>
        </div>
        <div class="cont-icon-fixed tres bg-azul mb-1 rounded-l-lg">
          <router-link to="/producto/credito-microcredito">
            <img
              src="@/assets/img/home/iconoBLANCO3.png"
              alt="icono"
              class="mx-auto h-12 md:h-16"
            />
            <span class="text-white text-sm text-center -mt-2 block px-2"
              >Créditos</span
            >
          </router-link>
        </div>
      </div>
    </div>
    <banner-principal />
    <!-- ROW ICONS -->
    <section>
      <iconos />
    </section>

    <!-- CARROUSEL -->
    <section>
      <home-carrousel />
    </section>

    <!-- TESTIMONIAL -->
    <section class="testimonials" style="background-color: #e2e2e2;">
      <div class="container">
        <h2 class="font-semibold azul text-3xl p-5">Nuestros Clientes</h2>
        <p class="azul -mt-6 pl-5 mb-5">Mejorarmos para servirte mejor</p>
        <testimonials />
      </div>
    </section>

    <!-- VIDEOS -->
    <section class="testimonials" style="background-color: #e2e2e2;">
      <div class="container">
        <h2 class="font-semibold azul text-3xl p-5">Experiencias Credil</h2>
        <div class="relative" style="padding-top: 56.25%">
          <iframe
            class="absolute inset-0 w-full h-full"
            autoplay
            src="https://www.youtube.com/embed/m5eo5LprHNg?autoplay=1&mute=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; muted"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>

    <!-- FORTALEZA -->
    <section>
      <div class="grid grid-cols-2 container my-20 ">
        <!-- col 1 -->
        <div class="col-span-2 md:col-span-1">
          <img src="@/assets/img/home/fortaleza.png" alt="fortaleza credil" />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-2 md:col-span-1 p-4 md:p-12 "
          style="background-color:#0a5495;"
        >
          <div class=" mt-5 md:mt-24">
            <h4 class="text-white text-5xl mb-6 font-bold leading-10">
              "Credil tu fortaleza<br />
              financiera"
            </h4>

            <p class="text-justify text-white mb-5">
              Lo que es imposible para los hombres, es posible para Dios. Lucas
              18, 27.
            </p>
            <br />
            <br />
            <p class="text-white font-semibold">
              Ing. Vinicio Lagua
            </p>
            <p class="text-white ">
              Gerente General
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import HomeCarrousel from "../components/home/HomeCarrousel.vue";
import Iconos from "../components/home/Iconos.vue";
import BannerPrincipal from "../components/home/BannerPrincipal.vue";
import Testimonials from "../components/home/Testimonials.vue";

export default {
  name: "Home",
  components: {
    BannerPrincipal,
    HomeCarrousel,
    Testimonials,
    Iconos
  },
  mounted() {
    this.$modal.show("modal-new-user");
  },
  methods: {
    show() {
      this.$modal.show("modal-new-user");
    },
    hide() {
      this.$modal.hide("modal-new-user");
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 690px) {
  #reloj::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 200px;
    background-color: #cd1f27;
    top: 250px;
  }
}

/* CALIFICACION */
#calificacion {
  background-color: #f3f3f3;
}

/* Blue icon fixed */
.cont-icon-fixed {
  width: 96px;
  margin-right: -6px;
  transition: all 0.4s ease;
}

.cont-icon-fixed.uno:hover {
  transform: translateX(-6px);
  opacity: 0.8;
}

.cont-icon-fixed.dos:hover {
  transform: translateX(-6px);
  opacity: 0.8;
}

.cont-icon-fixed.tres:hover {
  transform: translateX(-6px);
  opacity: 0.8;
}
</style>
