<template>
  <carousel
    :perPageCustom="[
      [380, 1],
      [768, 4]
    ]"
    :autoplay="true"
    :autoplayTimeout="3000"
    :paginationEnabled="false"
    :navigationEnabled="false"
    :autoplay-hover-pause="false"
    :loop="true"
    class="pb-10 px-5"
  >
    <!-- SLIDE 1 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center flex-col">
        <img
          src="@/assets/img/products/ahorroPlanFuturoIcons/iconoNinio.png"
          alt="services"
          class="h-20 p-3 mx-auto"
        />
        <h6 class="azul text-center mb-3">Mi Pequeño Gigante</h6>
      </div>
    </slide>

    <!-- SLIDE 2 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center flex-col">
        <img
          src="@/assets/img/products/ahorroPlanFuturoIcons/iconoNegocio.png"
          alt="servicios"
          class="h-20 p-3 mx-auto"
        />
        <h6 class="azul text-center mb-3">Mi Negocio</h6>
      </div>
    </slide>

    <!-- SLIDE 3 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center flex-col">
        <img
          src="@/assets/img/products/ahorroPlanFuturoIcons/iconoHogar.png"
          alt="servicios"
          class="h-20 p-3 mx-auto"
        />
        <h6 class="azul text-center mb-3">Mi Hogar</h6>
      </div>
    </slide>

    <!-- SLIDE 4 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center flex-col">
        <img
          src="@/assets/img/products/ahorroPlanFuturoIcons/iconoViaje.png"
          alt="servicios"
          class="h-20 p-3 mx-auto"
        />
        <h6 class="azul text-center mb-3">Mi Viaje</h6>
      </div>
    </slide>

    <!-- SLIDE 5 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center flex-col">
        <img
          src="@/assets/img/products/ahorroPlanFuturoIcons/iconoCarro.png"
          alt="servicios"
          class="h-20 p-3 mx-auto"
        />
        <h6 class="azul text-center mb-3">Mi Vehículo</h6>
      </div>
    </slide>

    <!-- SLIDE 6 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center flex-col">
        <img
          src="@/assets/img/products/ahorroPlanFuturoIcons/iconoEstudios.png"
          alt="servicios"
          class="h-20 p-3 mx-auto"
        />
        <h6 class="azul text-center mb-3">Mi Estudio Superior</h6>
      </div>
    </slide>

    <!-- SLIDE 7 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center flex-col">
        <img
          src="@/assets/img/products/ahorroPlanFuturoIcons/iconoEmprendimiento.png"
          alt="servicios"
          class="h-20 p-3 mx-auto"
        />
        <h6 class="azul text-center mb-3">Mi Emprendimiento</h6>
      </div>
    </slide>

    <!-- SLIDE 8 -->
    <slide class="bg-white mr-2 shadow-lg">
      <div class="flex content-center flex-col">
        <img
          src="@/assets/img/products/ahorroPlanFuturoIcons/iconoCasa.png"
          alt="servicios"
          class="h-20 p-3 mx-auto"
        />
        <h6 class="azul text-center mb-3">Mi Casa</h6>
      </div>
    </slide>
  </carousel>
</template>

<script>
export default {
  name: "AhorroPlanFuturoSlider"
};
</script>

<style>
.VueCarousel-slide {
  width: 90px !important;
}
.VueCarousel-slide > div {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
</style>
