<template>
  <main
    id="main"
    :style="{
      'background-image': 'url(' + require('@/assets/img/home/fondo.jpg') + ')'
    }"
  >
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/mailbox-suggestions/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Buzón de Sugerencias</h2>
        <p class="text-gray-100">Home / Buzón de Sugerencias</p>
      </div>
    </div>

    <!-- SECTION NOSOTROS -->
    <section class="pb-10">
      <div class="container mt-12  px-4 md:px-20">
        <!-- DESCRIPTION -->

        <div class="mx-auto" data-aos="fade-up" data-aos-duration="1000">
          <h4 class="text-3xl azul mb-2 font-bold text-center">
            Tu opinión es lo más importante
          </h4>

          <p class="azul text-center text-sm w-full md:w-3/4 mx-auto mb-10">
            Ayúdanos a mejorar, tu opinión nos permitirá conocer cómo brindarte
            un mejor servicio.
          </p>
        </div>
      </div>

      <!-- FORMULARIO -->
      <div
        class="container mx-auto w-full md:w-1/2 rounded "
        style="background-color: #e7e7e7;"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h2
          class="text-white rounded-t text-xl py-1 px-6 "
          style="background-color: #0a5590;"
        >
          Enviar una sugerencia
        </h2>
        <form class="p-4 md:p-6">
          <!-- col 1 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="names" class="azul font-bold block"
                >Nombre Completo</label
              >
              <input
                type="text"
                id="names"
                placeholder="JUAN PEREZ"
                v-model="name"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full md:w-11/12"
              />
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="phone" class="azul font-bold block">Teléfono</label>
              <input
                type="text"
                id="phone"
                placeholder="0981231234"
                v-model="phone"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full"
              />
            </div>
          </div>

          <!-- col 2 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-1">
              <label for="email" class="azul font-bold block">Correo</label>
              <input
                type="email"
                id="email"
                placeholder="email@email.com"
                v-model="email"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full md:w-11/12"
              />
            </div>
            <div class="col-span-2 md:col-span-1">
              <label for="city" class="azul font-bold block">Ciudad</label>
              <input
                type="text"
                id="city"
                placeholder="Ambato"
                v-model="city"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full"
              />
            </div>
          </div>

          <!-- col 3 -->
          <div class="grid grid-cols-2 mb-5">
            <div class="col-span-2 md:col-span-2">
              <label for="message" class="azul font-bold block">Mensaje</label>
              <textarea
                id="message"
                placeholder="Ingrese un mensaje"
                v-model="message"
                class="border border-gray-600 p-1 my-2 -pr-10 rounded focus:outline-none focus:ring-2 ring-blue-200 w-full"
              ></textarea>
            </div>
            <div class="col-span-2 md:col-span-2 mx-auto">
              <!-- botontes -->

              <button
                class="mt-5 py-1 px-10 rounded btn-enviar text-white"
                type="button"
                @click="sendInfo"
              >
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import validar_celular from "@/api/validar_celular";
import validar_email from "@/api/validar_email";
import api from "@/api/workWithUs";

export default {
  name: "mailSuggestions",
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      city: "",
      message: "",
      body: {}
    };
  },
  methods: {
    sendInfo() {
      if (
        this.names == "" ||
        this.email == "" ||
        this.phone == "" ||
        this.message == "" ||
        this.city == ""
      ) {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        if (validar_celular(this.phone)) {
          if (validar_email(this.email)) {
            // console.log(this.name, this.phone, this.email, this.city, this.message);
            this.body = {
              name: this.name,
              phone: this.phone,
              email: this.email,
              city: this.city,
              message: this.message
            };
            api.sendMailBoxSuggestions(this.body).then(resp => {
              if (resp.status == "ok") {
                this.$swal("Enviado", "", "success").then(() => {
                  this.$router.push("/");
                });
              } else {
                this.$swal("Error al guardar", "", "error");
              }
            });
          } else {
            this.$swal("Email no válido", "", "error");
          }
        } else {
          this.$swal("Celular no válidos", "", "error");
        }
      }
    }
  }
};
</script>

<style scoped>
/* main */
#main {
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100vw;
  z-index: 1;
}

#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
.btn-enviar {
  background-color: #0a5590;
}
.btn-enviar:hover {
  background-color: #0f87e2;
}
</style>
