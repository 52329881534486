import Vue from "vue";
import App from "./App.vue";
import "@/assets/css/tailwind.css";
import router from "@/router";
import store from "@/store";

// EXCEL
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

// LOADER SPINER
import { VueSpinners } from "@saeris/vue-spinners";

// MODAL
import VModal from "vue-js-modal";

// FONT ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faAngleDoubleRight,
  faEnvelope,
  faPhone,
  faMapMarked,
  faPen,
  faTrash,
  faEye,
  faBuilding,
  faArrowLeft,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faYoutube,
  faInstagram,
  faTwitter,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faEye,
  faPen,
  faTrash,
  faPhone,
  faMapMarked,
  faEnvelope,
  faAngleDoubleRight,
  faUserSecret,
  faFacebook,
  faYoutube,
  faInstagram,
  faTwitter,
  faWhatsapp,
  faBuilding,
  faArrowLeft,
  faArrowRight
);

// swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
// Carousel
import VueCarousel from "vue-carousel";

// sweetalert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// scroll animation
import AOS from "aos";
import "aos/dist/aos.css";

// messenger chat plugin
// import VueFbCustomerChat from "vue-fb-customer-chat";
// Vue.use(VueFbCustomerChat, {
//   page_id: 2558361357527182, //  change 'null' to your Facebook Page ID,
//   theme_color: "#3b5998", // theme color in HEX
//   locale: "es_ES" // default 'en_US'
// });

// WHATSAPP
import VueSocialChat from "vue-social-chat";
Vue.use(VueSocialChat);

// moment
Vue.use(require("vue-moment"));

Vue.use(VueSweetalert2);
Vue.use(VModal);
Vue.use(VueCarousel);
Vue.use(VueAwesomeSwiper);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueSpinners);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  created() {
    AOS.init();
  },
  render: h => h(App)
}).$mount("#app");
