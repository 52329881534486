<template>
  <div class="relative" @mouseover="showMenu" @mouseleave="hideMenu">
    <a
      href="#"
      @click="toggleMenu"
      class="block mt-4 lg:inline-block lg:mt-0 text-white  hover:text-gray-400  mr-4"
      >Productos & Servicios</a
    >
    <div class="absolute ">&nbsp;</div>
    <transition name="mega-menu-fade">
      <div
        v-show="isVisible"
        class="mega-menu absolute normal-case font-normal rounded-sm text-white"
      >
        <div class="flex bg-white rounded p-3 shadow flex-col md:flex-row">
          <div class="w-full md:w-1/3">
            <h6 class="font-bold text-gray-800 ml-16">
              Créditos
            </h6>
            <ul class="px-2 md:px-16">
              <li v-for="cred in creditos" :key="cred._id">
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white"
                  :to="'/producto/' + cred.slug"
                  >{{ cred.name }}</router-link
                >
              </li>
            </ul>
          </div>

          <!-- AHORROS -->
          <div class="w-full md:w-1/3">
            <h6 class="font-bold text-gray-800 ml-16">Ahorros</h6>
            <ul class="px-2 md:px-16">
              <li v-for="aho in ahorros" :key="aho._id">
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white"
                  :to="'/producto/' + aho.slug"
                  >{{ aho.name }}</router-link
                >
              </li>
            </ul>
          </div>

          <!-- Inversiones -->
          <div class="w-full md:w-1/3">
            <h6 class="font-bold text-gray-800 ml-16">Inversiones</h6>
            <ul class="px-2 md:px-16">
              <li v-for="inv in inversiones" :key="inv._id">
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white"
                  :to="'/producto/' + inv.slug"
                  >{{ inv.name }}</router-link
                >
              </li>
            </ul>
            <h6 class="font-bold text-gray-800 ml-16 mt-2">Servicios</h6>
            <ul class="px-2 md:px-16">
              <li>
                <router-link
                  class="text-sm py-2  px-1 font-normal block w-full whitespace-no-wrap bg-transparent  text-gray-800 hover:bg-blue-700 hover:text-white"
                  to="/servicios"
                  >Servicios</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import api from "@/api/product";
// import url from "@/api/url";

export default {
  name: "MegaMenu",
  data() {
    return {
      isVisible: false,
      creditos: [],
      ahorros: [],
      inversiones: []
    };
  },
  created() {
    this.getProduct();
  },
  methods: {
    showMenu() {
      this.isVisible = true;
    },
    hideMenu() {
      this.isVisible = false;
    },
    toggleMenu() {
      this.isVisible = !this.isVisible;
    },
    getProduct() {
      api.listProducts().then(products => {
        products.forEach(el => {
          if (el.simulator == "credito") {
            this.creditos.push(el);
          } else if (el.simulator == "0") {
            this.ahorros.push(el);
          } else if (el.simulator == "inversiones") {
            this.inversiones.push(el);
          }
        });
        //console.log("creditos", this.creditos, "ahorros", this.ahorros, "inv", this.inversiones)
      });
    }
  }
};
</script>
<style scoped>
.mega-menu {
  width: 80vw;
  z-index: 2;
  top: 30px;
  left: -30vw;
  right: 0;
  padding: 15px;
  color: #000;
  /* transform: translateX(-30%); */
}
.mega-menu-fade-enter-active,
.mega-menu-fade-leave-active {
  transition: all 1s ease-in-out;
}
.mega-menu-fade-enter,
.mega-menu-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}
@media screen and (max-width: 690px) {
  .mega-menu {
    left: -10vw;
  }
}
</style>
