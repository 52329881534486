<template>
  <footer style="background-color: #0a5495;">
    <div class="container p-5">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 md:col-span-5">
          <img
            src="@/assets/img/home/logoBLANCO.png"
            alt="logo Credil"
            class="h-40"
          />

          <hr class="gris" />
          <a href="mailto:coopcredil@hotmail.com" target="_blank">
            <font-awesome-icon :icon="['fas', 'envelope']" class="gris" />
            <span class="gris" style="margin-left:20px;"
              >coopcredil@hotmail.com</span
            >
          </a>
          <hr class="gris" />
          <a href="tel:+592032730307">
            <font-awesome-icon :icon="['fas', 'phone']" class="gris" />
            <span class="gris" style="margin-left:20px;">(03)2 730 307</span>
          </a>
          <hr class="gris" />
          <div class="flex justify-between redes">
            <h5 class="text-white mb-4">Síguenos</h5>
            <a href="https://www.facebook.com/coac.credil.ltda/" target="_blank"
              ><img
                src="@/assets/img/home/social/facebook.png"
                alt="facebook"
                class="w-5 h-5 hover:opacity-50"
            /></a>

            <a
              href="https://www.youtube.com/channel/UC6yALcY1GChgv6S7lIPPOEQ"
              target="_blank"
              ><img
                src="@/assets/img/home/social/youtube.png"
                alt="youtube"
                class="w-5 h-5 hover:opacity-50"
            /></a>

            <!-- <a
              href="https://twitter.com/CoopCrediYa?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              ><font-awesome-icon
                :icon="['fab', 'twitter']"
                class="gris hover:text-gray-400"
            /></a>

            <a href="https://whatsapp.com/" target="_blank"
              ><font-awesome-icon
                :icon="['fab', 'whatsapp']"
                class="gris hover:text-gray-400"
            /></a> -->

            <a href="https://www.instagram.com/coop_credil_ltda" target="_blank"
              ><img
                src="@/assets/img/home/social/instagram.png"
                alt="instagram"
                class="w-5 h-5 hover:opacity-50"
            /></a>
          </div>
          <div class="text-center">
            <img
              src="@/assets/img/home/code.png"
              alt="code qr"
              class="h-40 mx-auto"
            />
          </div>
        </div>

        <div class="col-span-12 mt-5 md:col-start-7 md:col-end-13">
          <h5 class="text-white mb-4 font-semibold text-xl">Direcciones</h5>
          <hr class="gris" />
          <p class="gris">Matriz Salcedo</p>
          <font-awesome-icon :icon="['fas', 'building']" class="gris" />
          <span class="gris text-sm" style="margin-left:20px;"
            >Salcedo Av. 24 de mayo y Ana Paredes</span
          >

          <hr class="gris" />
          <p class="gris">Sucursal Ambato</p>
          <font-awesome-icon :icon="['fas', 'building']" class="gris" />
          <span class="gris text-sm" style="margin-left:20px;"
            >Ambato centro Av. 12 de Noviembre y Maldonado</span
          >

          <hr class="gris" />
          <p class="gris">Sucursal Ambato - Izamba</p>
          <font-awesome-icon :icon="['fas', 'building']" class="gris" />
          <span class="gris text-sm" style="margin-left:20px;"
            >Izamba Av. Pedro Vasconez, entre el ex Redondel de Izamba y el
            Colegio Tirso de Molina</span
          >

          <hr class="gris" />
          <p class="gris">Sucursal Ambato - Mercado Mayorista</p>
          <font-awesome-icon :icon="['fas', 'building']" class="gris" />
          <span class="gris text-sm" style="margin-left:20px;"
            >Av. Bolivariana Interior del Mercado Mayorista</span
          >

          <hr class="gris" />
          <p class="gris">Sucursal Riobamba</p>
          <font-awesome-icon :icon="['fas', 'building']" class="gris" />
          <span class="gris text-sm" style="margin-left:20px;"
            >Chile y Juan Montalvo</span
          >
          <hr class="gris" />
          <p class="gris">Sucursal Machala</p>
          <font-awesome-icon :icon="['fas', 'building']" class="gris" />
          <span class="gris text-sm" style="margin-left:20px;"
            >Av. 25 de Junio entre Buenavista y Colón</span
          >
          <hr class="gris" />
          <p class="gris">Sucursal Guayaquil</p>
          <font-awesome-icon :icon="['fas', 'building']" class="gris" />
          <span class="gris text-sm" style="margin-left:20px;"
            >Av. 9 de Octubre y Lorenzo de Garaycoa</span
          >
          <hr class="gris" />
          <hr class="gris" />
          <p class="gris">Sucursal Quito-Cotocollao</p>
          <font-awesome-icon :icon="['fas', 'building']" class="gris" />
          <span class="gris text-sm" style="margin-left:20px;"
            >Av. Diego de Vázquez y Nazacota Puento (esq.)</span
          >
          <hr class="gris" />
          <hr class="gris" />
          <p class="gris">Sucursal Pasaje</p>
          <font-awesome-icon :icon="['fas', 'building']" class="gris" />
          <span class="gris text-sm" style="margin-left:20px;"
            >Av. Rocafuerte y 9 de Octubre</span
          >
          <hr class="gris" />
          <hr class="gris" />
          <p class="gris">Sucursal Piñas</p>
          <font-awesome-icon :icon="['fas', 'building']" class="gris" />
          <span class="gris text-sm" style="margin-left:20px;"
            >Av. La independencia y Juan Montalvo</span
          >
          <hr class="gris" />
          <img
            src="@/assets/img/home/icono24.png"
            alt="icono 24 horas"
            class="inline h-16"
          />
          <span class="gris text-sm" style="margin-left:20px;"
            >Lunes a Viernes - 8am a 5pm</span
          >

          <!-- <div class="flex justify-between mt-5">
            <a href="https://www.cosede.gob.ec/" target="_blank">
              <img
                src="@/assets/img/footer/cosede.png"
                alt="cosede"
                class="h-8 opacity-100 hover:opacity-75"
              />
            </a>
            <a href="https://www.bce.fin.ec/" target="_blank">
              <img
                src="@/assets/img/footer/banco-central.png"
                alt="banco central"
                class="h-8 opacity-100 hover:opacity-75"
              />
            </a>
            <a href="https://www.seps.gob.ec/" target="_blank">
              <img
                src="@/assets/img/footer/superintendencia.png"
                alt="banco central"
                class="h-8 opacity-100 hover:opacity-75"
              />
            </a>
          </div> -->
        </div>
        <!-- <div class="col-span-12 mt-32 md:col-start-11 md:col-end-13">
          
        </div> -->
      </div>
    </div>
    <div style="background-color:#0f6cab;">
      <p class="text-gray-400 text-center text-sm py-2">
        Cooperativa de Ahorro y Crédito Ltda. @2023
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
/* COLORES DE LETRAS */
.rojo {
  color: #cd1f27;
}
.bg-rojo {
  background-color: #cd1e2a;
}
.gris {
  color: rgb(224, 224, 224);
}
hr {
  margin: 10px 0;
  background-color: rgb(224, 224, 224);
  height: 1px;
  /* background-color: #ccc; */
  border: none;
}
</style>
