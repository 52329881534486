<template>
  <div>
    <!-- LIGHTBOX -->
    <CoolLightBox :items="items" :index="index" @close="index = null">
    </CoolLightBox>

    <div class="images-wrapper">
      <div
        class="image m-2"
        style="background-size:cover; cursor:pointer;"
        v-for="(image, imageIndex) in items"
        :key="imageIndex"
        @click="index = imageIndex"
      >
        <div class="circulo my-10">
          <img src="@/assets/img/governance/org.png" alt="organigrama credil" />
          <p class="text-center azul mx-4 text-ms font-semibold -mt-8">
            Organigrama
          </p>
        </div>
      </div>
    </div>
    <!-- FIN LIGHTBOX -->
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "LightBox",
  components: {
    CoolLightBox
  },
  props: ["img1", "img2", "img3"],
  data() {
    return {
      items: [require(`@/assets/img${this.img1}`)],
      index: null
    };
  }
};
</script>
<style scoped>
.circulo {
  width: 180px;
  height: 180px;
  padding: 10px;
  box-shadow: none;
  transition: all 0.1s ease;
  position: relative;
  top: 0px;
  box-shadow: 0 0 5px #999;
  border-radius: 50%;
}
</style>
