<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/responsability/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Responsabilidad social</h2>
        <p class="text-gray-100">Home / Responsabilidad social</p>
      </div>
    </div>

    <!-- CONTENT -->
    <section>
      <div class="container mt-12  px-4 md:px-20">
        <!-- DESCRIPTION -->
        <div class="grid grid-cols-2 mb-12">
          <div
            class="col-span-2 md:col-span-2 mb-2"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <h4 class="text-3xl text-gray-700 mb-4 uppercase text-center">
              Responsabilidad Social
            </h4>
            <p class="text-gray-600">
              Cooperativa CrediYa se preocupa por el bienestar social, donde ha
              ejecutado acciones en beneficio de la comunidad. Nuestro trabajo
              está enfocado a mejorar las condiciones de vida de nuestros
              Socios, Clientes y Comunidad.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- PROJECT 1 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-16 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/responsability/navidad/img-1.jpg"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">
            AGASAJO NAVIDEÑO A NUESTROS SOCIOS
          </h5>
          <p class="gris text-justify mb-10">
            Credi Ya, celebró a sus socios con un pequeño agasajo navideño en
            donde nuestra institución realizó la entrega de pequeños obsequios
            en agradecimiento a la confianza depositada en nuestra cooperativa.
          </p>
          <light-box
            img1="/navidad/img-1.jpg"
            img2="/navidad/img-2.jpeg"
            img3="/navidad/img-3.jpeg"
          />
        </div>
      </div>
    </section>

    <!-- PROJECT 2 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-8 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">
            LABOR SOCIAL
          </h5>
          <p class="gris text-justify mb-10">
            La Cooperativa de Ahorro y Crédito Credi Ya Ltda., cumpliendo con su
            compromiso con las comunidades de nuestro Cantón, realizó la entrega
            de caramelos a los más pequeños, así como sonrisas y mucha
            diversión; labor que se realizó con mucha empatía en varios sectores
            de nuestra ciudad.
          </p>
          <light-box
            img1="/laborSocial/img-1.jpeg"
            img2="/laborSocial/img-2.jpg"
            img3="/laborSocial/img-3.jpg"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/responsability/laborSocial/img-1.jpeg"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>
      </div>
    </section>

    <!-- PROJECT 3 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-16 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/responsability/atahualpaSub40/img-1.jpg"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">
            ENTREGA DE MEDALLAS CAMPEONATO SUB-40
          </h5>
          <p class="gris text-justify mb-10">
            Credi Ya, realizó la entrega de medallas a los ganadores del
            Campeonato Sub-40 realizado en la parroquia Atahualpa, con una gran
            acogida por sus organizadores la cooperativa engalano con su
            participación en el acto de premiación.
          </p>
          <light-box
            img1="/atahualpaSub40/img-1.jpg"
            img2="/atahualpaSub40/img-2.jpg"
            img3="/atahualpaSub40/img-3.jpg"
          />
        </div>
      </div>
    </section>

    <!-- PROJECT 4 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-8 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">
            CARRERA ATLÉTICA
          </h5>
          <p class="gris text-justify mb-10">
            La Cooperativa de Ahorro y Crédito Credi Ya Ltda., dentro de sus
            compromisos sociales participó como uno de los Auspiciantes de la
            Carrera Atlética organizada en la parroquia Picaihua, en donde
            entregaron premios a los respectivos ganadores, cumpliendo así una
            vez más con el compromiso en las parroquias de nuestra ciudad.
          </p>
          <light-box
            img1="/carreraAtletica/img-1.jpg"
            img2="/carreraAtletica/img-2.jpg"
            img3="/carreraAtletica/img-3.jpg"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/responsability/carreraAtletica/img-1.jpg"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>
      </div>
    </section>

    <!-- PROJECT 5 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-16 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/responsability/diaDelCooperativismo/img-1.jpg"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">
            DÍA DEL COOPERATIVISMO
          </h5>
          <p class="gris text-justify mb-10">
            La Cooperativa Credi Ya Ltda., celebró junto con sus socios y
            clientes el Día del Cooperativismo, entregando unos pequeños
            detalles a nuestros socios para así mantener el espíritu cívico en
            nuestros colaboradores, sin dejar pasar por alto una fecha
            importante para el sector del Cooperativismo.
          </p>
          <light-box
            img1="/diaDelCooperativismo/img-1.jpg"
            img2="/diaDelCooperativismo/img-2.jpg"
            img3="/diaDelCooperativismo/img-3.jpg"
          />
        </div>
      </div>
    </section>

    <!-- PROJECT 6 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-8 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">
            DÍA DEL PADRE
          </h5>
          <p class="gris text-justify mb-10">
            Una fecha tan especial para los héroes del hogar, la Cooperativa
            Credi Ya realizó un homenaje con una Transmisión en vivo por el Día
            del Padre; en donde se engalanó a todos los jefes del hogar, así
            como a nuestros socios y funcionarios; también hubo la entrega de
            premios por parte de la cooperativa por la participación en nuestras
            redes sociales con La Mejor Frase para Papá.
          </p>
          <light-box
            img1="/diaDelPadre/img-1.jpg"
            img2="/diaDelPadre/img-2.jpg"
            img3="/diaDelPadre/img-3.jpg"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/responsability/diaDelPadre/img-1.jpg"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>
      </div>
    </section>

    <!-- PROJECT 7 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-16 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/responsability/entregaMarcarillas/img-1.jpg"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">
            ENTREGA DE MASCARILLAS
          </h5>
          <p class="gris text-justify mb-10">
            Credi ya ante la Emergencia Sanitaria que estamos viviendo en
            nuestro país y preocupados por el bienestar de nuestros Socios y
            Clientes, la Ing. Cristina Santamaria obsequió materiales de
            bioseguridad a nuestros funcionarios y socios de la institución,
            para precautelar la salud de todos quienes son parte de la
            institución.
          </p>
          <light-box
            img1="/entregaMarcarillas/img-1.jpg"
            img2="/entregaMarcarillas/img-2.jpg"
            img3="/entregaMarcarillas/img-3.jpg"
          />
        </div>
      </div>
    </section>

    <!-- PROJECT 8 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-8 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">
            ENTREGA DE RECONOCIMIENTO AL DR. EDMUNDO MANTILLA
          </h5>
          <p class="gris text-justify mb-10">
            Credi Ya siempre se ha destacado en ser una Institución que Admira
            la profesionalidad de las personas, en este caso reconociendo el
            largo trabajo del Dr. Edmundo Mantilla. Credi Ya presente en sus 50
            años de vida Profesional.
          </p>
          <light-box
            img1="/medallasDrEdmundo/img-1.jpg"
            img2="/medallasDrEdmundo/img-2.jpg"
            img3="/medallasDrEdmundo/img-3.jpg"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/responsability/medallasDrEdmundo/img-1.jpg"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>
      </div>
    </section>

    <!-- PROJECT 9 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-16 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/responsability/situacionFinanciera/img-1.jpg"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">
            HABLEMOS DE SITUACIÓN FINANCIERA
          </h5>
          <p class="gris text-justify mb-10">
            Para el crecimiento y fortalecimiento de nuestra Institución nos
            mantenemos en constantes capacitaciones con nuestros Socios y
            Clientes, llevando así a cabo la Capacitación Tomemos un Café y
            Hablemos de Situación Financiera, realizado en el Hotel Ambato
            además celebramos el día del amor y la amistad conjuntamente con
            nuestros socios.
          </p>
          <light-box
            img1="/situacionFinanciera/img-1.jpg"
            img2="/situacionFinanciera/img-2.jpg"
            img3="/situacionFinanciera/img-3.jpg"
          />
        </div>
      </div>
    </section>

    <!-- PROJECT 10 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-8 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">
            NEURO EMOCIONES EN MOMENTOS DE CRISIS
          </h5>
          <p class="gris text-justify mb-10">
            Credi Ya, velando por el bienestar de nuestros Socios/Clientes y
            colaboradores de la Institución se mantiene en constantes
            capacitaciones y más aún en momentos de Crisis; buscando los medios
            posibles para motivar e incentivar a nuestros socios.
          </p>
          <light-box
            img1="/neuroEmociones/img-1.jpg"
            img2="/neuroEmociones/img-2.jpg"
            img3="/neuroEmociones/img-3.jpg"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/responsability/neuroEmociones/img-1.jpg"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>
      </div>
    </section>

    <!-- PROJECT 11 -->
    <section class="bg-red-600 pl-4 md:pl-20 pt-4 md:pt-16 pb-5">
      <div class="grid grid-cols-5 mx-auto px-4 md:px-32 bg-white">
        <!-- col 1 -->
        <div
          class="col-span-5 md:col-span-2 z-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img
            src="@/assets/img/responsability/vacacional/img-1.jpg"
            alt="gerente crediya"
            width="400"
            class="mx-auto"
          />
        </div>

        <!-- col 2 -->
        <div
          class="col-span-5 md:col-span-3 p-4 md:p-10"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h5 class="gris font-semibold text-2xl">
            VACACIONAL CREDI YA
          </h5>
          <p class="gris text-justify mb-10">
            Manteniéndonos siempre innovados Credi Ya realizó una transmisión en
            vivo por inicio de clases de los más pequeños del hogar, también se
            les incentivó a que realicen un Títere con materiales que tengan en
            su hogar; alegrando de una manera especial los corazones de los más
            pequeños para su retorno a clases.
          </p>
          <light-box
            img1="/vacacional/img-1.jpg"
            img2="/vacacional/img-2.jpg"
            img3="/vacacional/img-3.jpg"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import LightBox from "../../components/howWeAre/LightBox.vue";

export default {
  name: "Responsability",
  components: {
    LightBox
  }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}

.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal-right {
  position: absolute;
  right: 10%;
  top: 40%;
}
</style>
