<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/simulator/banner-inv.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <div class="contenedor-imagen pt-20">
          <img
            src="@/assets/img/simulator/icono-inv.png"
            alt="icono simulador"
            class="mx-auto"
            style="height: 200px; width:auto;"
          />
        </div>
        <h2 class="text-white text-3xl font-bold text-center">
          Simulador de Inversiones
        </h2>
        <p class="text-gray-100 text-center">
          Grandes beneficios por tus inversiones
        </p>
      </div>
    </div>

    <section class="container py-5 px-4 md:px-32 mx-auto">
      <h2 class="text-2xl text-white bg-azul-fuerte rounded py-1 px-5">
        Ingrese los datos
      </h2>
      <div class="container p-4 md:p-10 mx-auto bg-white  rounded-lg shadow-md">
        <!-- FILA 1 -->
        <div class="grid grid-cols-7">
          <!-- CANTIDAD -->
          <div class="col-span-7 md:col-span-2">
            <div class="rounded  mr-0 md:mr-10">
              <label for="tipoCredito" class="block font-semibold azul"
                >Monto a Invertir</label
              >
              <input
                type="number"
                id="cantidad"
                v-model="cantidad"
                class="w-full p-1 my-1 mr-1 rounded focus:outline-none border block"
                placeholder="Monto"
              />

              <label for="tipoCredito" class="block font-semibold azul mt-5"
                >Tiempo a Invertir</label
              >
              <p class="azul text-sm mb-3 -mt-2">
                Coloca el tiempo en meses
              </p>
              <select
                name="plazo"
                id="plazo"
                v-model="plazo"
                class="w-full p-1 my-1 mr-1 rounded focus:outline-none border block"
              >
                <option value="3">3 Meses</option>
                <option value="6">6 Meses</option>
                <option value="9">9 Meses</option>
                <option value="11">11 Meses</option>
                <option value="12">12 Meses</option>
                <option value="18">18 Meses</option>
                <option value="24">24 Meses</option>
              </select>

              <label
                for="tipoContribuyente"
                class="block font-semibold azul mt-5"
                >Tipo de Contribuyente</label
              >
              <select
                name="tipoContribuyente"
                id="tipoContribuyente"
                v-model="tipoContribuyente"
                class="w-full p-1 my-1 mr-1 rounded focus:outline-none border block"
              >
                <option value="natural">Natural</option>
                <option value="contribuyente">Juridico</option>
              </select>

              <!-- BOTONERA -->
              <div class="mt-5">
                <button
                  type="button"
                  class="bg-gray-700 px-5 py-2 mr-5  rounded text-white  focus:outline-none hover:bg-gray-600"
                  @click="simulation"
                  @keydown.tab.exact.prevent=""
                >
                  Simular
                </button>

                <button
                  type="button"
                  class="bg-gray-500 px-5 py-2 rounded text-white focus:outline-none hover:bg-gray-600"
                  @click="limpiar"
                  @keydown.tab.exact.prevent=""
                >
                  Limpiar
                </button>
              </div>
            </div>
          </div>

          <!-- COL 2 -->
          <!-- RESULTS -->
          <div class="col-span-7 md:col-span-5">
            <div class="result bg-azul-fuerte rounded-lg p-4  mt-10">
              <div class="grid grid-cols-2">
                <!-- COLUMNA 1 -->
                <div class="col-span-2 md:col-span-1">
                  <h4 class="text-white text-2xl font-semibold mb-4">
                    Cálculo
                  </h4>
                  <div class="container flex justify-between mb-2">
                    <label for="capital" class="text-white">Capital</label>
                    <input
                      type="number"
                      name="capital"
                      readonly
                      v-model="cantidad"
                      class="w-2/4 text-right rounded text-sm"
                    />
                  </div>
                  <div class="container flex justify-between mb-2">
                    <label for="capital" class="text-white"
                      >Interés a Ganar</label
                    >
                    <input
                      type="number"
                      name="capital"
                      readonly
                      v-model="interesGanar"
                      class="w-2/4 text-right rounded text-sm"
                    />
                  </div>
                  <div class="container flex justify-between mb-2">
                    <div class="flex flex-col">
                      <label for="capital" class="text-white text-sm"
                        >IRF</label
                      >
                      <span class="text-white" style="font-size:.5rem"
                        >(Inpuesto Rendimiento Financiero)</span
                      >
                    </div>
                    <input
                      type="number"
                      name="capital"
                      readonly
                      v-model="irf"
                      class="w-2/4 text-right rounded text-sm"
                    />
                  </div>
                </div>

                <!-- COLUMNA 2 -->
                <div class="col-span-2 md:col-span-1">
                  <h4
                    class="text-white text-xl font-normal mt-1 mb-3 text-center"
                  >
                    Total Neto a recibir
                  </h4>
                  <h3
                    class="text-white text-3xl font-bold mx-2 px-2 text-center "
                  >
                    $ {{ total }}
                  </h3>
                </div>
              </div>
            </div>

            <!-- Solicitar -->
            <div class="flex justify-center mt-6">
              <router-link
                to="/formulario-inversiones"
                class="bg-gray-700 px-3 md:px-10 py-2 rounded text-white focus:outline-none hover:bg-gray-600 "
              >
                Solicitar
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import api from "@/api/simulator";
export default {
  name: "SimulatorCredit",
  data() {
    return {
      cantidad: "",
      plazo: "",
      tipoContribuyente: "",
      interesGanar: "",
      irf: "",
      total: 0
    };
  },
  methods: {
    simulation() {
      if (
        this.cantidad == "" ||
        this.plazo == "" ||
        this.tipoContribuyente == ""
      ) {
        this.$swal("Llene todo los campos", "", "error");
      } else {
        api
          .consultInversion(this.cantidad, this.plazo, this.tipoContribuyente)
          .then(resp => {
            if (resp.status == "ok") {
              this.$swal("Éxito", "", "success").then(() => {});
              this.interesGanar = Number(resp.interesGanado.toFixed(2));
              this.irf = Number(resp.impuestaRenta.toFixed(2));
              this.total =
                parseFloat(this.cantidad) +
                parseFloat(Number(resp.totalRecibir.toFixed(2)));
            } else {
              this.$swal("Error", "", "error");
            }
          });
      }
    },
    limpiar() {
      this.cantidad = "";
      this.plazo = "";
    }
  }
};
</script>

<style scoped>
/* Banner */
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  width: 100%;
  position: relative;
}

.bg-azul-fuerte {
  background-color: #063a79;
}
</style>
