<template>
  <div class="flex flex-col md:flex-row justify-between m-10">
    <!-- Icono 1 -->
    <div class="contenedor mx-auto icono">
      <router-link to="/producto/ahorro-plan-futuro">
        <img
          src="@/assets/img/home/icono1.png"
          alt="icono credil"
          class="mx-auto h-24"
        />
        <h5 class="text-center azul font-semibold -mt-2">Ahorros</h5>
        <p class="text-center azul mx-4 text-xs">
          Aplica para el tipo de ahorro que más se acople a tus necesidades.
        </p>
      </router-link>
    </div>

    <!-- Icono 2 -->
    <div class="contenedor mx-auto icono">
      <router-link to="/producto/credito-microcredito">
        <img
          src="@/assets/img/home/icono2.png"
          alt="icono credil"
          class="mx-auto h-24"
        />
        <h5 class="text-center azul font-semibold -mt-2">Créditos</h5>
        <p class="text-center azul mx-4 text-xs">
          Construye tus proyectos junto a nosotros.
        </p>
      </router-link>
    </div>

    <!-- Icono 3 -->
    <div class="contenedor mx-auto icono">
      <router-link to="/producto/depositos-a-plazo-fijo">
        <img
          src="@/assets/img/home/icono3.png"
          alt="icono credil"
          class="mx-auto h-24"
        />
        <h5 class="text-center azul font-semibold -mt-2">Inversiones</h5>
        <p class="text-center azul mx-4 text-xs">
          Tu dinero gana la mejor tasa de interés.
        </p>
      </router-link>
    </div>

    <!-- Icono 4 -->
    <div class="contenedor mx-auto icono">
      <router-link to="/solicitudes">
        <img
          src="@/assets/img/home/icono4.png"
          alt="icono credil"
          class="mx-auto h-24"
        />
        <h5 class="text-center azul font-semibold -mt-2">Solicitudes</h5>
        <p class="text-center azul mx-4 text-xs">
          Solicita nuestros productos, un asesor se pondrá en contacto.
        </p>
      </router-link>
    </div>

    <!-- Icono 5 -->
    <div class="contenedor mx-auto icono">
      <router-link to="/servicios">
        <img
          src="@/assets/img/home/icono5.png"
          alt="icono credil"
          class="mx-auto h-24"
        />
        <h5 class="text-center azul font-semibold -mt-2">Servicios</h5>
        <p class="text-center azul mx-4 text-xs">
          Paga todos tus servicios en nuestras agencias.
        </p>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "Iconos"
};
</script>

<style scoped>
/* ICONS */
.icono {
  width: 220px;
  height: 220px;
  padding: 10px;
  box-shadow: none;
  transition: all 0.1s ease;
  border-radius: 50%;
  background-color: white;
}
.icono:hover {
  position: relative;
  top: 0px;
  box-shadow: 0 0 7px #999;
  border-radius: 50%;
  transform: translateY(-0.4px);
}
</style>
