<template>
  <div>
    <bounce-loader
      class="flex justify-center text-center"
      :loading="isLoading"
      :color="'#f00'"
      :size="100"
    />
    <header-c v-if="!isLoading" />
    <router-view v-if="!isLoading" />
    <SocialChat icon :attendants="attendants" dir="rtl">
      <template v-slot:button>
        <img
          src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
          class="mx-auto"
        />
      </template>
    </SocialChat>
    <footer-c v-if="!isLoading" />
  </div>
</template>

<script>
import FooterC from "./components/FooterC.vue";
import HeaderC from "./components/HeaderC.vue";

export default {
  name: "App",
  components: {
    HeaderC,
    FooterC
  },
  data() {
    return {
      isLoading: true,
      attendants: [
        {
          app: "whatsapp",
          label: "Cooperativa Credil",
          name: "Chatea con Nosotros",
          number: "593968979728",
          avatar: {
            src: require("@/assets/img/home/logo.png"),
            alt: "Credil"
          }
        }
      ]
    };
  },
  computed: {
    logueado() {
      return this.$store.state.user;
    },
    isAdmin() {
      return this.$store.state.user && this.$store.state.user.type == "1";
    }
  },
  created() {
    this.$store.dispatch("autoLogin");
    this.isLoading = false;
  }
};
</script>
<style>
.rojo {
  color: #cd1f27;
}
.bg-rojo {
  background-color: #cd1e2a;
}
.gris {
  color: #575757;
}
.azul {
  color: #175696;
}
.bg-azul {
  background-color: #0a5595;
}

/* WHATSAPP */
* {
  --vsc-bg-header: #00bb2d;
  --vsc-bg-button: #00bb2d;
  --vsc-outline-color: #00bb2d;
}
.vsc-popup-button.vsc-popup-button--default > img {
  margin: 0 auto;
}
</style>
