import { render, staticRenderFns } from "./RequestInvestment.vue?vue&type=template&id=30aaf451&scoped=true"
import script from "./RequestInvestment.vue?vue&type=script&lang=js"
export * from "./RequestInvestment.vue?vue&type=script&lang=js"
import style0 from "./RequestInvestment.vue?vue&type=style&index=0&id=30aaf451&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30aaf451",
  null
  
)

export default component.exports